import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/initialization.css"; 
import 'swiper/dist/css/swiper.min.css' // ES6 导入swiper css文件
import 'animate.css';
import WOW from 'wow.js';
import ElementPlus from 'element-plus' //elementUI 
import 'element-plus/dist/index.css' //elementUI 
import * as ElementPlusIconsVue from '@element-plus/icons-vue' //elementUI 
import zhCn from 'element-plus/es/locale/lang/zh-cn' //elementUI 语言默认




import { createHead } from '@unhead/vue' // 挂载head标签

const wow = new WOW();
wow.init();

var app= createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}



app.use(ElementPlus, {
    locale: zhCn,
  })
app.use(store)
app.use(router)
const head = createHead() // 挂载head标签
app.use(head) // 挂载head标签

app.config.errorHandler = (err, vm, info) => {
  console.error('全局错误处理器:', err, info);
};
app.config.globalProperties.$apiBaseUrl = 'https://abc.yanfabu.com';
app.mount("#app");







