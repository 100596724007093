<template>
  <div class="container-w">
    <header class="lz-hade">


      <div class="lz-logo">
        <router-link to="/"><img :src="getImgUrl(logoimg)" alt=""></router-link>
      </div>
      <div class="lz-nav">
        <ul>
          <li>
            <router-link to="/">首页</router-link>
          </li>
          <li> <router-link to="/Curriculum">课程学习</router-link>
            <div class="lz-nav-fd">
              <div class="container-w">
                  <div class="lz-nav-list" v-for="index in navlist" :key="index">
                     <div class="tet-fl">
                      <router-link :to="'/Curriculum?cid=' + index.id"
                      @click="kecfel(index.categoryname,index.id)" >{{index.categoryname}}</router-link>
                     </div>
                     <div class="tet-fr">
                      <router-link :to="erjilink(index.id,data.id)"  @click="ejkecfel(index.id,index.categoryname)" v-for="data in index.children"
                      :key="data">{{data.categoryname}}</router-link>
            
                     </div>
                  </div>
                
              </div>
              <!-- <div class="container-w" id="naveji">
                      <router-link :to="'/Curriculum?cid=' + index.id" @mouseover="showDropdown(index.categoryname,index.id)"
                  @click="kecfel(index.categoryname,index.id)" v-for="index in navlist"
                  :key="index">{{index.categoryname}}</router-link>

              </div>
              <div class="lz-nav-fd-list">
                <div class="lz-nav-fd-list-fite">

                  <router-link :to="erjilink(index)" v-for="index in kcfllista"
                    :key="index">{{index.categoryname}}</router-link>
                </div>
              </div> -->
            </div>
          </li>
          <li><router-link to="/News">资讯动态</router-link></li>
          <li><router-link to="/About">关于我们</router-link></li>
        </ul>
      </div>
      <div class="lz-settings">
        <div class="lz-search">
          <el-icon @click="Searcha()">
            <Search />
          </el-icon>
          <input type="text" placeholder="搜索" v-model="searchQuery" @keyup.enter="handleSearch">

        </div>
        <div class="yt-login" v-if="!member">
          <img src="../assets/images/icon-my.png" alt="">
          <router-link to="/Login">登录</router-link>
          <span>|</span>
          <router-link to="/Register">注册</router-link>
        </div>
        <div class="yt-login" v-if="member">
          <img src="../assets/images/icon-my.png" alt="">
          <router-link to="/My">个人中心</router-link>
          <span>|</span>
          <a href="javascript:;" @click="exit()">退出</a>
        </div>
        <div class="but">
          <a href="javascript:;" @click="sqrj()">申请软件试用</a>
        </div>
        <div class="sj-cd" @click="sidebtn()">
          <img src="../assets/images/icon-cd.png" alt="">
        </div>
      </div>

    </header>
    <section>
      <div class="bx-side-nav2" @click="showbtn()">
        <el-icon>
          <CloseBold />
        </el-icon>
      </div>
      <div class="bx-side-nav-list2">
        <div class="div-title"> <router-link to="/"><img :src="getImgUrl(logoimg)" alt=""></router-link></div>
        <ul>
          <li>
            <router-link to="/">首页</router-link>
          </li>
          <li> <router-link to="/Curriculum">课程学习</router-link>
            <div class="sj-nav-fd">
              <router-link :to="'/Curriculum?cid=' + index.id" @click="kecfel(index.categoryname)"
                v-for="index in navlist" :key="index">{{index.categoryname}}</router-link>
            </div>
          </li>
          <li><router-link to="/News">资讯动态</router-link>

          </li>
          <li><router-link to="/About">关于我们</router-link>
            <div class="sj-nav-fd">
              <router-link to="/Course">发展历程</router-link>
            </div>
          </li>
          <li> <a href="javascript:;" @click="sqrj()">申请软件试用</a></li>
        </ul>

      </div>
    </section>
  </div>


</template>

<script setup>

  import { ref } from 'vue';
  const searchQuery = ref('');
  import { useRouter } from 'vue-router'; // 引入useRouter
  const router = useRouter(); // 使用useRouter获取路由器实例

  const handleSearch = () => {
    if (searchQuery.value.trim() === '') {
      // alert('请输入搜索关键词！');
      ElMessage({
        message: '请输入搜索关键词！',
        type: 'error',
      })
      return;
    }
    // ...执行搜索逻辑
    router.push({ path: '/Search', query: { key: searchQuery.value } });

  };

  function Searcha() {
    const list = this;
    if (searchQuery.value.trim() === '') {

      ElMessage({
        message: '请输入搜索关键词！',
        type: 'error',
      })
      return;
    }
    router.push({ path: '/Search', query: { key: searchQuery.value } });
  }

</script>

<script>
  import http from "@/axios/http.js";
  import $ from 'jquery';
  import { ElMessage } from 'element-plus'
  export default {
    name: "HeaderPage",
    props: {},
    data() {
      return {
        navlist: [],
        kcfllista: [],
        member: false,
        searchQuery: '',
        url: '',
        logoimg: '',
        yjid: '',
      }
    },

    methods: {
      getImgUrl(pic) {
        return this.url + pic;
      },
      sidebtn() {
        $(".bx-side-nav2").show();
        $(".bx-side-nav-list2").css("right", "0");

      },
      showbtn() {
        $(".bx-side-nav2").hide();
        $(".bx-side-nav-list2").css("right", "-260px");

      },
      // showDropdown(name,id) {
      //   var list = this;
      //   list.yjid = id;
      //   $(".lz-nav-fd-list").show();
      //   localStorage.setItem("categoryname", name);

      //   http.get('/CourseCategory/GetCourseCategoryAllList',
      //     {
      //       params: {
      //         page: "1", limit: "100", parentid: list.yjid
      //       }
      //     }
      //   )
      //     .then(function (response) {
      //       list.kcfllista = response.data.data;
      //     })
      //     .catch(function (error) {
      //     });

      // },
      ejkecfel(i,n){
        localStorage.setItem("categoryid", i);
        localStorage.setItem("categoryname", n);
      },
      erjilink(i,d) {
        return `/Curriculum?cid=` + i + '&id=' + d;
      },

      kecfel(n, i) {
        $(".lz-nav-fd").hide();
        localStorage.setItem("categoryname", n);
        localStorage.setItem("categoryid", i);
        // this.$router.push({ path: '/Curriculum'});
      },
      exit() {
        localStorage.removeItem("token");
        localStorage.removeItem("member");
        this.member = false
        this.$router.push({ path: '/Login' });

      },
      sqrj() {
        const list = this;
        $(".bx-side-nav2").hide();
        $(".bx-side-nav-list2").css("right", "-260px");
        if (list.member == false) {
          this.$message({
            showClose: true,
            message: "您还未登录登录",
            type: 'error'
          })
        } else {
          this.$router.push({ path: '/MyApply' });
        }
      }
    },
    mounted() {
      const list = this;
      list.url = this.$apiBaseUrl;
      http.get('/CourseCategory/GetCourseCategoryList',
        {
          params: {
            parentid: 0
          }
        }
      )
        .then(function (response) {
          list.navlist = response.data.data
        })
        .catch(function (error) {

        });











      // 获取会员信息

      http.get('/member/getMemberDetail', {
        headers: {
          "token": localStorage.getItem("token")
        }
      }
      )
        .then(function (response) {
          if (response.data.code == '0') {
            list.member = true

          } else {
            list.member = false;
            localStorage.removeItem("token");
          }

        })
        .catch(function (error) {

        });


      // logo
      http.get('/siteConfig/getSiteConfigDetail', {
        params: {
          code: "logo",
        },


      }
      )
        .then(function (response) {
          if (response.data.code == '0') {
            list.logoimg = response.data.data.value

          }

        })
        .catch(function (error) {

        });

    },
  };


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .lz-hade {
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 8;
    height: 100px;
    border-bottom: solid 1px #ffffff30;
    background-color: #fff;
  }

  .lz-hade2 {
    background-color: #fff;
    box-shadow: 0 2px 8px 2px rgba(198, 201, 208, 0.5)
  }

  .lz-hade .lz-logo img {
    height: 40px;
  }

  .lz-hade .lz-nav {
    margin-left: 10%;
  }

  .lz-hade .lz-nav ul {
    display: flex;
    align-items: center;
    padding-right: 25px;

  }

  .lz-hade .lz-nav ul li {
    padding: 0 25px;
    font-size: 16px;
    line-height: 100px;

  }

  .lz-hade .lz-nav ul li a {
    position: relative;
    color: #000;
    display: block;
  }

  .lz-hade .lz-nav ul li:hover>a,
  .lz-hade .lz-nav ul li.cur a {
    color: #FF6600;
  }

  .lz-hade .lz-nav ul li:hover>a:before,
  .lz-hade .lz-nav ul li.cur>a::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FF6600;
  }

  .lz-hade2 .lz-nav ul li a:hover:before,
  .lz-hade2 .lz-nav ul li.cur a::before {
    content: '';
    background-color: var(--color);
  }

  .lz-hade .lz-settings {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .lz-hade .lz-settings .but a {
    display: block;
    color: #fff;
    padding: 10px 20px 10px 40px;
    border-radius: 5px;
    font-size: 16px;
    background: #115F9F url(../assets/images/icon-bi.png) no-repeat left 15px center;
    background-size: 20px;

  }

  .lz-hade .lz-settings .but a:hover {
    opacity: 0.8;
  }

  .lz-search {
    height: 40px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    /* background: #F6F7F8 url(../assets/images/icon-ss.png) no-repeat left 10px center; */
    background-size: 20px;
    padding: 0 10px 0 10px;
  }

  .lz-search i {
    font-size: 20px;
    color: #000;
    cursor: pointer;
  }

  .lz-search input {
    height: 40px;
    width: calc(100% - 30px);
    border: none;
    outline: none;
    background: none;
    font-size: 16px;
    color: #000;
  }

  .yt-login {
    padding: 0 20px;
    display: flex;
    align-items: center;
    color: #999;
  }

  .yt-login img {
    width: 22px;
    height: 22px;

  }

  .yt-login a {
    font-size: 16px;
    color: #333;
    padding: 0 10px;
  }

  .yt-login a:hover {
    color: #FF6600;
  }

  .lz-nav-fd {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px 0;
    display: none;
    border-top: solid 1px #eee;
    transition: all 0.5s;
    z-index: 10000;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    max-height: 80vh;
    overflow: auto;
  }

  /* .lz-nav-fd .container-w {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

  } */
  .lz-nav-fd  .lz-nav-list{
    padding: 10px 0;
    display: flex;
  }
  .lz-nav-fd  .lz-nav-list .tet-fl{
    width: 200px;
    font-size: 16px;
    text-align: right;
  }
  .lz-nav-fd  .lz-nav-list .tet-fl a{
    color: #000;
   font-weight: bold;
    margin:4px 10px;
  }
  .lz-nav-fd  .lz-nav-list .tet-fr{
    width: calc(100% - 200px);
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
  .lz-nav-fd  .lz-nav-list .tet-fr a{
    position: relative;
    color: #666;
    margin: 4px 10px;
  }
  .lz-nav-fd  .lz-nav-list .tet-fr a::after{
    content: '';
    position: absolute;
    width: 1px;
    height: 15px;
    right: -10px;
    top: 11px;
    background-color: #99999980;
  }
  .lz-nav-fd  .lz-nav-list .tet-fr a:last-child:after{
    display: none;
  }
  .lz-nav-fd a {
    display: inline-block;
    line-height: 25px;
    padding: 5px 10px;
    background-color: #F6F7F8;
    margin: 4px 10px;
    border-radius: 5px;
  }

  .lz-nav-fd a:hover::before {
    content: '';
    display: none;
  }

  .lz-nav-fd a:hover {
    /* background-color: #FF6600; */
    color: #FF6600 !important;
  }

  .lz-hade .lz-nav ul li:hover .lz-nav-fd {
    display: block !important;
  }

  .sj-cd {
    display: none;
  }

  .lz-nav-fd-list {
    padding: 0px 10%;
    display: none;
    border-top: solid 1px #eee;
  }

  .lz-nav-fd-list .lz-nav-fd-list-fite {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;


  }

  @media (max-width: 1520px) {
    .lz-hade .lz-nav {
      margin-left: 40px;
    }

    .lz-hade .lz-nav ul {
      padding-right: 15px;

    }

    .lz-hade .lz-nav ul li {
      padding: 0 15px;

    }
  }

  @media (max-width: 1320px) {
    .lz-hade .lz-nav {
      margin-left: 20px;
    }

    .lz-hade .lz-nav ul li,
    .yt-login a,
    .lz-hade .lz-settings .but a,
    .lz-search input,
    .yt-login a {
      font-size: 14px;
    }

  }

  @media (max-width: 1280px) {
    .lz-hade {
      height: 80px;
      position: relative;
    }

    .lz-hade .lz-nav {
      display: none;
    }


    .sj-cd {
      cursor: pointer;
      width: 30px;
      margin-left: 10px;
      display: block;
    }
  }

  @media (max-width: 1000px) {
    .lz-hade .lz-settings .but {
      display: none;
    }

    .lz-search {
      display: none;
    }

    .lz-hade {
      height: 50px;
    }

    .lz-hade .lz-logo img {
      height: 25px;
    }

    .yt-login {
      padding: 0;
    }
  }
</style>