import axios from 'axios';
const service = axios.create({
   baseURL: 'https://abc.yanfabu.com',
    //  baseURL: 'http://localhost:8086', // 替换为你的API基础URL
  timeout: 5000 // 请求超时时间
});



export default service;




