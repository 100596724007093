<template>
  <div>
    <footer class="footer footer002">
      <div class="container-w">
        <div class="footer-item">
          <div class="footer-nav">
            <ul>
              <li>
                <h3>课程学习</h3>
                <div class="footkcli">
                  <!-- <a class="text-ellipsis" v-for="index in footlist" :key="index" href="#">一维流体仿真</a> -->

                  <router-link  v-for="index in footlist" :key="index"  :to="'/Curriculum?cid=' + index.id" @click="kecfel(index.categoryname)">{{index.categoryname}}</router-link>
                </div>

              </li>
              <li>
                <h3>关于我们</h3>
                <router-link to="/About">公司简介</router-link>
                <a href="javascript:;" @click="goToAnchor()">企业文化</a>
                <router-link to="/Course">发展历程</router-link>
       
              </li>
              <li>
                <h3>联系我们</h3>
                <p>
                  <i class="icon-hd"></i><span>{{footone.s03}}</span>
                </p>
                <p><i class="icon-yx"></i><span>{{footone.s02}}</span></p>
                <p><i class="icon-dz"></i>
                  <span>{{footone.s04}}</span></p>

              </li>
              <li>
                <div class="ewm">
                  <div class="ewm-f">
                    <p>扫码关注我们</p>

                    <img  :src="getImgUrl(footone.filepath + footone.picture)">
                  </div>
                  <div class="ewm-f">
                    <p>客服微信</p>

                    <img  :src="getImgUrl(footone.filepath + footone.f01)">
                  </div>
                  <div class="ewm-f">
                    <p>客服微信</p>

                    <img  :src="getImgUrl(footone.filepath + footone.f02)">
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-bottom">{{footone.detailcontent}}
      </div>
    </footer>
</div>
</template>

<script>
  import http from "@/axios/http.js";
  export default {
    name: "FooterPage",
    props: {},
    data() {
      return {
        footone: [],
        footlist: [],
        url: ''
      }
    },
    methods: {
      getImgUrl(pic) {
        return this.url + pic;
       },
      goToAnchor() {
        // 使用路由的push方法来跳转到带有锚点的URL
        this.$router.push({ path: '/About', hash: '#your-anchor' });
      },
      kecfel(categoryname) {
        localStorage.setItem("categoryname", categoryname);
        // this.$router.push({ path: '/Curriculum'});
      },
    },
    mounted() {
      var list = this;
      list.url = this.$apiBaseUrl;
      http.get('/article/getArticleDetailByTC',
        { 
          params: {
            typecode: "siteInfo",
          }
          }
      )
        .then(function (response) {
          list.footone = response.data.data

        })
        .catch(function (error) {
          // console.log(error);
        });


      http.get('/CourseCategory/GetCourseCategoryAllList',
        {
          params: {
            page: "1", limit: "6", parentid: 0,sort:"AddTime Asc"
          }
        }
      )
        .then(function (response) {
          list.footlist = response.data.data
        })
        .catch(function (error) {

        });
    },
  };


</script>


<style>
  .footer002.footer {
    width: 100%;
    background-color: #222222;
  }

  .footer002 .footer-item {
    padding-top: 45px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
  }

  .footer002 .footer-logo {
    width: 20%;
  }

  .footer002 .footer-logo .footl {
    display: block;
    height: 60px;
  }

  .footer002 .footer-logo .footl img {
    max-height: 100%
  }

  .footlink {
    display: flex;
    align-items: center;
    padding-top: 30px;
  }

  .footlink a {
    display: block;
    width: 40px;
    height: 40px;
    background: #ffffff20;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 10px;
  }

  .footlink a img {
    max-height: 15px;
  }

  .footer002 .footer-nav {
    width: 100%;
  }

  .footer002 .footer-nav ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .footer002 .footer-nav ul li:first-child{
    width: 25%;
  }
  .footer002 .footer-nav ul li:nth-child(2) {
    width: 10%;
  }
  .footer002 .footer-nav ul li:nth-child(3) {
    width: 25%;
  }
  .footer002 .footer-nav ul li:nth-child(4) {
    width: 30%;
    text-align: center;
  }

  .footer002 .footer-nav ul li .footkcli{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  .footer002 .footer-nav ul li .footkcli a{
    width: 50%;
    padding: 10px;
  }
  .footer002 .footer-nav ul li .ewm {
    display: flex;
    justify-content: space-between;
  }

  .footer002 .footer-nav ul li .ewm-f {
    width: 32%
  }

  .footer002 .footer-nav ul li .ewm-f p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
    line-height: 30px;
    margin-bottom: 10px;

  }

  .footer002 .footer-nav ul li a {
    display: block;
    font-size: 14px;
    color: rgba(255, 255, 255, .6);
    padding: 10px 0;
  }

  .footer002 .footer-nav ul li a:hover {
    color: #FF6600;
  }

  .footer002 .footer-nav ul li h3 {
    font-size: 18px;
    color: #efefef;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .footer002 .footer-nav ul li p {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: rgba(255, 255, 255, .6);
    padding: 10px 0 10px 0px;
    position: relative;
    
  }
  .footer002 .footer-nav ul li p span{
    flex: 1;
  }
  .footer002 .footer-nav ul li p i {
    margin-right: 10px;
  }


  .footer002 .footer-nav ul li img {
    width: 120px
  }

  .footer002 .footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, .1);
    text-align: center;
    padding: 20px 10px;
    font-size: 14px;
    color: rgba(255, 255, 255, .5);
  }

  .footer002 .footer-bottom a {
    font-size: 14px;
    color: rgba(255, 255, 255, .4);
  }

  .footer002 .footer-bottom a:hover {
    color: #fff;
  }
.icon-hd{
  display: block;
  width: 20px;
  height: 20px;
  background: url(../assets/images/icon-dh.png) no-repeat center;
  background-size: 12px
}
.icon-yx{
  display: block;
  width: 20px;
  height: 20px;
  background: url(../assets/images/icon-yx.png) no-repeat center;
  background-size: 12px
}
.icon-dz{
  display: block;
  width: 20px;
  height: 20px;
  background: url(../assets/images/icon-dz.png) no-repeat center;
  background-size: 12px
}
@media (max-width: 1000px) {
  .footer002 .footer-nav ul {
    flex-wrap: wrap;
  }
  .footer002 .footer-nav ul li:nth-child(1),
  .footer002 .footer-nav ul li:nth-child(2){
    display: none;
  }
  .footer002 .footer-nav ul li{
    width: 100% !important;
  }
  .footer002 .footer-nav ul li:nth-child(4){
    padding-top: 20px;
  }
  .footer002 .footer-nav ul li img{
    width: 80px;
  }
}
</style>