<template>
  <Header></Header>
  <div class="Index">
    <div class="lz-banner">


      <div class="swiper mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="index in bannerlist" :key="index"><img
              :src="getImgUrl(index.filepath + index.picture)" alt=""></div>

        </div>
        <!-- <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div> -->
        <div class="swiper-pagination"></div>
      </div>

    </div>
    <div class="container-w">
      <div class="index-one">
        <div class="curriculum-fl">
          <div class="sy-title">
            <h3>课程预告</h3>
          </div>
          <div class="swiper mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="index in courseyg" :key="index">

                <router-link target="_blank" :to="'/CurriculumygDetail?id=' + index.id" class="bx-img-hover">
                  <div class="pic">
                    <img :src="getImgUrl(index.filepath + index.picture)" alt="">
                    <span>{{index.author}}</span>
                  </div>
                  <div class="tet">
                    <h3>{{index.articletitle}}</h3>
                    <p><span class="span1">{{index.s01}}</span> {{index.s02}}</p>
                  </div>
                </router-link>

              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="curriculum-fr">
          <div class="sy-title">
            <h3>课程推荐</h3>
            <router-link to="/Curriculum">查看更多></router-link>
          </div>
          <ul>
            <li v-for="index in kclist" :key="index" class="bx-img-hover">
              <router-link target="_blank" v-if="isCourseTypeValid(index.s01)" :to="getCourseDetailPath(index)"
                @click="kcclick(index.id)" class="bx-img-hover">
                <div class="pic">
                  <img :src="getImgUrlkc(index.courseimg)" alt="">
                  <span>{{ index.s04 }}</span>
                </div>
                <div class="tet">
                  <h3>{{ index.coursename }}</h3>
                  <p><span class="span1">{{ index.categoryname }}</span> <span class="span2">{{index.s02}}</span></p>
                </div>
              </router-link>
            </li>
          </ul>
        </div>

      </div>

      <div class="index-one index-tow">
        <div class="sy-title">
          <h3>最新课程列表</h3>
        </div>
        <ul>
          <li v-for="index in zxkclist" :key="index" class="bx-img-hover">
            <router-link target="_blank"  v-if="isCourseTypeValid(index.s01)" :to="getCourseDetailPath(index)" @click="kcclick(index.id)"
              class="bx-img-hover">
              <div class="pic">
                <img :src="getImgUrlkc(index.courseimg)" alt="">
                <span>{{ index.s04 }}</span>
              </div>
              <div class="tet">
                <h3>{{ index.coursename }}</h3>
                <p><span class="span1">{{ index.categoryname }}</span> <span class="span2">{{index.s02}}</span></p>
               
              </div>
            </router-link>
          </li>
        </ul>
        <div class="more">
          <router-link to="/Curriculum">查看更多课程</router-link>

        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>


<script>
  // @ is an alias to /src
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  import http from "@/axios/http.js";
  import $ from 'jquery';
  import Swiper from "swiper";


  export default {
    name: "MyIndex",
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        bannerlist: [],
        courseyg: [],
        kclist: [],
        zxkclist: 16,
        url: ''
      }
    },
    methods: {
      isCourseTypeValid(type) {
        return ['视频课程', '直播课程'].includes(type);
      },
      getCourseDetailPath(index) {
        const paths = {
          '视频课程': '/CurriculumDetail',
          '直播课程': '/CurriculumDetail2'
        };
        return `${paths[index.s01]}` + '?id=' + index.id;
      },
      // kcclick(id) {
      //   localStorage.setItem("kid", id);   
      // },

      getImgUrl(pic) {
        return this.url + pic;
      },
      getImgUrlkc(pic) {
        return this.url + '/UserFiles/Article/' + pic;
      },
      open() {
        this.$message({
          message: "13",
          type: 'success'
        })
      },
      Swiperlist() {
        this.$nextTick(() => {
          new Swiper(".lz-banner .mySwiper", {
            spaceBetween: 0,
            centeredSlides: true,
            speed: 1000, // 匀速
            // loop: true,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false,
            },
            pagination: {
              el: ".lz-banner  .swiper-pagination",
              clickable: true,
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        })
      },
      Swiperlistkc() {
        this.$nextTick(() => {
          new Swiper(".curriculum-fl .mySwiper", {
            spaceBetween: 10,
            centeredSlides: true,
            speed: 1000, // 匀速
            // loop: true,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false,
            },
            pagination: {
              el: ".curriculum-fl .swiper-pagination",
              clickable: true,
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        })
      }
    },
    mounted() {

      const tast = this
      tast.url = this.$apiBaseUrl;

      http.get('/article/getArticleListNoAuth',
        {
          params: {
            page: "1", limit: "10", typecode: "Banner", language: "zh_cn"
          }
        }
      ).then(function (response) {
        // console.log(response);
        tast.bannerlist = response.data.data;
        tast.Swiperlist()
      })
        .catch(function (error) {
          // console.log(error);
        });

      // 课程预告
      http.get('/article/getArticleListNoAuth',
        {
          params: {
            page: "1", limit: "10", typecode: "courseyg", language: "zh_cn"
          }
        }
      ).then(function (response) {
        // console.log(response);
        $.each(response.data.data, function (i, t) {
          t.addtime = t.addtime.split(" ")[0]
        })
        tast.courseyg = response.data.data;
        tast.Swiperlistkc()
      })
        .catch(function (error) {
          // console.log(error);
        });

      // 推荐课程
      http.get('/Course/GetCourseList',
        {
          params: {
            page: "1", limit: "4", s03: "1", status: '1'
          }
        }
      ).then(function (response) {

        tast.kclist = response.data.data;
      })
        .catch(function (error) {
          // console.log(error);
        });

      // 课程列表
      http.get('/Course/GetCourseList',
        {
          params: {
            page: "1", limit: "20", status: '1'
          }
        }
      ).then(function (response) {
        tast.zxkclist = response.data.data;
      })
        .catch(function (error) {
          // console.log(error);
        });



    },



  };


</script>

<style scoped>
  .lz-banner {
    position: relative;
    overflow: hidden;
  }

  .lz-banner .swiper-slide {
    width: 100% !important;
  }

  .index-one {
    display: flex;
    justify-content: space-between;
    padding: 60px 0;
  }

  .index-one .curriculum-fl {
    position: relative;
    width: 48%;
    overflow: hidden;
  }

  .index-one .curriculum-fl .swiper {
    margin-top: 30px;
  }

  .index-one .curriculum-fr {
    width: 48%;
  }

  .index-one .curriculum-fr ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

  }

  .index-one .curriculum-fr ul li {
    width: 48%;
    margin-top: 30px;
  }

  .sy-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #115F9F;
  }

  .sy-title h3 {
    font-size: 28px;
    color: #fff;
    background-color: #115F9F;
    border-radius: 10px 10px 0 0;
    padding: 5px 20px;
  }

  .sy-title a {
    color: #666;
    font-size: 16px;
  }

  .sy-title a:hover {
    color: #FF6600
  }

  .index-one a {
    display: block;
    box-shadow: 0px 0px 20px 0px rgba(128, 141, 161, 0.15);
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    background-color: #fff; 
  }

  .index-one .pic {
    position: relative;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
  }

  /* .index-one .curriculum-fl .pic {
    height: 450px;
  }

  .index-one .curriculum-fr .pic {
    height: 200px;
  } */

  .index-one .pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .index-one .pic span {
    font-size: 14px;
    color: #fff;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .index-one .tet {
    padding: 10px 20px 20px 20px;
  }

  .index-one .curriculum-fl .tet {
    padding: 20px 20px 75px 20px;
  }

  .index-one .curriculum-fl .tet h3 {
    font-size: 24px;
    line-height: 35px;
    height: 70px;
  }

  .index-one .tet h3 {
    font-size: 16px;
    color: #000;
    line-height: 25px;
    height: 50px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .index-one .tet p {
    font-size: 16px;
    color: #666;
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .index-one .curriculum-fl .tet p {
    font-size: 16px;
    color: #666;
    padding-top: 25px;

  }

  .index-one .tet p .span1 {
    background-color: #115F9F15;
    border-radius: 5px;
    color: #115F9F;
    font-size: 14px;
    padding: 5px;
    margin-right: 5px;
  }
  .index-one .tet p .span2 {
    font-size: 14px;
    width: 160px;
    padding: 0 5px;
    display: inline-block;
    line-height: 27px;
    height: 27px;
    text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
  }

  .index-one .curriculum-fl a:hover .tet h3 {
    color: #FF6600;
  }

  .index-one .curriculum-fr a:hover{
    background-color: #FF6600;
  }

  .index-one .curriculum-fr a:hover .tet h3,
  .index-one .curriculum-fr a:hover .tet p {
    color: #fff;
  }

  .index-one .curriculum-fr a:hover .tet p span {
    background-color: #ffffff20;
    color: #fff;
  }

  .index-tow {
    display: block;
    padding: 0 0 60px 0;
  }

  .index-tow .sy-title {
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .index-tow ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -10px;
    padding-top: 20px;

  }

  .index-tow ul li {
    width: 25%;
    padding: 10px;
    margin: 0;
  }

  .index-tow ul li a:hover{
    background-color: #FF6600;
  }

  .index-tow ul li a:hover .tet h3,
  .index-tow ul li a:hover .tet p {
    color: #fff;
  }

  .index-tow ul li a:hover .tet p span {
    background-color: #ffffff20;
    color: #fff;
  }

  .more {
    text-align: center;
    padding-top: 40px;
  }

  .more a {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #115F9F;
    font-size: 16px;
    color: #fff;
  }

  .more a:hover {
    opacity: 0.8;
  }

  @media (max-width:1000px) {

    .index-one {
      display: flex;
      flex-wrap: wrap;
      padding: 40px 0;
    }

    .index-one .curriculum-fl {
      width: 100%;
    }

    .index-one .curriculum-fl .swiper {
      margin-top: 20px;
    }

    .index-one .curriculum-fr {
      width: 100%;
      padding-top: 40px;
    }

    .index-one .curriculum-fr ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

    }

    .index-one .curriculum-fr ul li {
      width: 48%;
      margin-top: 20px;
    }

    .sy-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px #115F9F;
    }

    .sy-title h3 {
      font-size: 22px;
    }

    .sy-title a {
      font-size: 14px;
    }

    .index-one .pic span {
      font-size: 14px;
      color: #fff;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    .index-one .tet {
      padding: 10px 10px 10px 10px;
    }

    .index-one .curriculum-fl .tet {
      padding: 10px 10px 20px 10px;
    }

    .index-one .curriculum-fl .tet h3 {
      font-size: 16px;
      line-height: 25px;
      height: auto
    }

    .index-one .tet h3 {
      font-size: 16px;
      color: #000;
      line-height: 25px;
      height: 50px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .index-one .tet p {
      font-size: 14px;
      color: #666;
      padding-top: 15px;
    }

    .index-one .curriculum-fl .tet p {
      font-size: 14px;
      color: #666;
      padding-top: 25px;
    }

    .index-tow {
      display: block;
      padding: 0 0 40px 0;
    }


    .index-tow ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0 -5px;
      padding-top: 20px;

    }

    .index-tow ul li {
      width: 50%;
      padding: 5px;
      margin: 0;
    }

    .more a {
      font-size: 14px;
      color: #fff;
    }


  }
</style>