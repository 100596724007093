import { createRouter, createWebHashHistory } from "vue-router";
import Index from "../views/Index.vue";


const routes = [
  {
    path: "/",
    name: "home",
    component: Index,
  },
  {
    path: "/Curriculum",
    name: "Curriculum",
    component: () =>
      import("../views/Curriculum.vue"),
  },
  {
    path: "/CurriculumDetail",
    name: "CurriculumDetail",
    component: () =>
      import("../views/CurriculumDetail.vue"),
  },
  {
    path: "/CurriculumDetail2",
    name: "CurriculumDetail2",
    component: () =>
      import("../views/CurriculumDetail2.vue"),
  },
  {
    path: "/CurriculumygDetail",
    name: "CurriculumygDetail",
    component: () =>
      import("../views/CurriculumygDetail.vue"),
  },
  {
    path: "/News",
    name: "News",
    component: () =>
      import("../views/News.vue"),
  }
  ,
  {
    path: "/NewsDetail",
    name: "NewsDetail",
    component: () =>
      import("../views/NewsDetail.vue"),
  },
  {
    path: "/About",
    name: "About",
    component: () =>
      import("../views/About.vue"),
  },
  {
    path: "/Course",
    name: "Course",
    component: () =>
      import("../views/Course.vue"),
  },
  {
    path: "/Login",
    name: "Login",
    component: () =>
      import("../views/Login.vue"),
  },
  {
    path: "/Register",
    name: "Register",
    component: () =>
      import("../views/Register.vue"),
  },
  {
    path: "/ForgotPassword",
    name: "ForgotPassword",
    component: () =>
      import("../views/ForgotPassword.vue"),
  },
  {
    path: "/My",
    name: "My",
    component: () =>
      import("../views/My.vue"),
  },
  {
    path: "/MyCourse",
    name: "MyCourse",
    component: () =>
      import("../views/MyCourse.vue"),
  },
  {
    path: "/MyCollection",
    name: "MyCollection",
    component: () =>
      import("../views/MyCollection.vue"),
  },
  {
    path: "/MyApply",
    name: "MyApply",
    component: () =>
      import("../views/MyApply.vue"),
  },
  {
    path: "/ChangePassword",
    name: "ChangePassword",
    component: () =>
      import("../views/ChangePassword.vue"),
  }
  ,
  {
    path: "/VideoDetail",
    name: "VideoDetail",
    component: () =>
      import("../views/VideoDetail.vue"),
  }
  ,
  {
    path: "/Search",
    name: "Search",
    component: () =>
      import("../views/Search.vue"),
  }
  ,
  {
    path: "/CurriculumDetailhtll",
    name: "CurriculumDetailhtll",
    component: () =>
      import("../views/CurriculumDetailhtll.vue"),
  }
  ,
  {
    path: "/Agreement",
    name: "Agreement",
    component: () =>
      import("../views/Agreement.vue"),
  }
   
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // 如果目标URL包含hash，则滚动到对应的元素
      return {
        el: to.hash,
        behavior: 'smooth' // 可选的滚动行为
      };
    }
    // 其他情况下的滚动行为
    return { top: 0 };
  }

});


// 站点seo
// import { useHead } from '@unhead/vue'

// useHead({
//   title: '网站标题',
//   meta: [
//     {
//       name: 'description',
//       content: 'My page description',
//     }, {
//       name: 'keywords',
//       content: 'My page keywords',
//     },
//   ],
// })


export default router;
